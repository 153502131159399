import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { AppConfig } from '@config/app.config';
import { CoreModule } from '@core/core.module';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeIt);

if (environment.enableBugsnag) {
  let stage = 'development';
  if (environment.production) {
    stage = 'production';
  }

  if (environment.isTest) {
    stage = 'staging';
  }

  if (environment.isDemo) {
    stage = 'demo';
  }

  Bugsnag.start({
    apiKey: AppConfig.bugsnagApiKey,
    appVersion: AppConfig.version,
    releaseStage: stage,
    autoTrackSessions: false,
  });
}

class MyErrorHandler implements ErrorHandler {
  handleError(error) {
    console.error(error);
  }
}

function errorHandlerFactory(error: any) {
  if (environment.enableBugsnag) {
    return new BugsnagErrorHandler();
  }

  return new MyErrorHandler();
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production || environment.isTest,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
  ],
})
export class AppModule {}
