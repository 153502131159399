import { Injectable } from '@angular/core';
import { SYNC_TOKEN } from '@shared/event-tokens';
import { OicEventService } from './oic-event.service';
import { OicHttpService } from './oic-http.service';
import { OicPermissionService } from './oic-permission.service';
import { OicPopAlertService } from './oic-popalert.service';

@Injectable()
export class OicSyncService {
  get canSync(): boolean {
    return this.permissionService.isGranted('sync.manuale');
  }

  constructor(
    private httpService: OicHttpService,
    private popAlert: OicPopAlertService,
    private permissionService: OicPermissionService,
    private eventService: OicEventService
  ) {}

  /**
   * Forza la sincronizzazione
   *
   * @returns {void}
   * @memberof MpmSyncService
   */
  sincronizzaCloudForce(): void {
    if (!this.canSync) {
      return;
    }

    this.httpService.get('/cloud/sync').subscribe((_) => {
      this.pushSyncLogs();
      this.popAlert.showInfoMessage('MSG.SYNC.SUCCESS');
    });
  }

  // Notifica ai componenti che la sync è stata completata (anche in caso di errore)
  private pushSyncLogs(): void {
    this.eventService.dispatch(SYNC_TOKEN, true);
  }
}
