import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OicPopAlertComponent } from './components/oic-popalert.component';
import { AuthGuardService } from './services/auth-guard.service';
import { OicCoreService } from './services/oic-core.service';
import { OicHttpInterceptor } from './services/oic-http-interceptor';
import { OicHttpService } from './services/oic-http.service';
import { OicPopAlertService } from './services/oic-popalert.service';
import { OicStateService } from './services/oic-state.service';
import { OicStorageService } from './services/oic-storage.service';
import { OicSyncService } from './services/oic-sync.service';
import { PermissionGuardService } from './services/permission-guard.service';
import { PwaService } from './services/pwa-service.service';

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [OicPopAlertComponent],
  exports: [OicPopAlertComponent],
  providers: [
    OicCoreService,
    OicHttpService,
    OicPopAlertService,
    OicStorageService,
    OicStateService,
    OicSyncService,
    AuthGuardService,
    PermissionGuardService,
    PwaService,
    { provide: HTTP_INTERCEPTORS, useClass: OicHttpInterceptor, multi: true },
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    translate: TranslateService
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }

    translate.addLangs(['it', 'en']);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/it|en/) ? browserLang : 'en');
  }
}
