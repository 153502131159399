import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'secure/tasks', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('./pages/authentication/authentication.module').then((m) => m.AuthenticationModule) },
  { path: 'secure', loadChildren: () => import('./shell/shell.module').then((m) => m.ShellModule) },
  { path: '**', redirectTo: 'secure/dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
